import {IBlogPageResponse, IBlogResponse, IStrapiAttributes, IStrapiData,} from '@redux/types';
import {call, put, takeEvery} from 'redux-saga/effects';
import api from '@api/index';
import {generateBlog, generateBlogDetail} from '@common/utils/generateData';
import {getStrapiQuery} from '@common/functions';
import * as actions from './actions';
import {EBlogsActionType, IBlogGetAction} from '@redux/blogs/types';


function* getBlogPage() {
  try {
    let query = getStrapiQuery({
      fields: ['title'],
      populate: 'meta,tags.title,blogs.image,blogs.cardType,blogs.tag,blogs.meta,blogs.url'
    })
    const response: IStrapiData<IStrapiAttributes<IBlogPageResponse>> = yield call(() => api.get('/blog-page', {query}))
    const data = response.data.attributes
    const {title, meta, tags} = data
    const blogs = generateBlog(data.blogs.data ?? [])
    yield put(actions.setBlogs({
      page: {title, meta},
      tags: tags.data.map(t => t.attributes),
      blogs
    }))
  } catch (e) {
    console.log('Fetch failed, reason: ', e)
  }
}

function* getBlog({payload}: IBlogGetAction) {
  try {
    const {slug} = payload
    let query = getStrapiQuery({
      populate: 'deep,4'
    })
    const response: IStrapiData<IStrapiAttributes<IBlogResponse>> = yield call(() => api.get(`/blogs/${slug}`, {query}))
    const data = response.data.attributes
    const {meta} = data
    const blog = generateBlogDetail(data)
    yield put(actions.setBlogs({
      page: {meta},
      blog
    }))
  } catch (e) {
    yield put(actions.setBlogs({
      blog: null
    }))
    console.log('Fetch failed, reason: ', e)
  }
}


export default [
  takeEvery(EBlogsActionType.GET_BLOG_PAGE, getBlogPage),
  takeEvery(EBlogsActionType.GET_BLOG, getBlog),
]
