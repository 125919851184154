import {
  CardType, IAward,
  IAwardResponse, IBlog, IBlogResponse, IEntityDetail,
  INews, INewsResponse, IProjectResponse, IReview, IReviewResponse,
  IServiceFeedback, IServiceFeedbackResponse, IStrapiAttributes,
  IStrapiData, IStrapiRichText, ITag,
} from '@redux/types';
import {IProject} from '@redux/projects/types';
import moment from 'moment';
import {YEAR_FORMAT} from '@common/constants';
import {getEntityDetail} from '@common/functions';
import {getSteps} from '@common/utils/getSteps';

/** Преобразование данных страницы Политика конфиденциальности */
export const generatePersonalInformationText = (text: IStrapiRichText, classNames: string = 'simple-link simple-link--no-color simple-link--font-big') => {
  let title = ''
  text?.children?.forEach(d => {
    if (d.type === 'text') {
      title += d.text
    } else if (d.type === 'link') {
      if (d.children) {
        title += `<a class="${classNames}" href="${d.url}">${d.children[0].text}</a>`
      }
    }
  })
  return title
}

/** Преобразование и сортировка данных для блока Рейтинги и награды */
export const generateAwardsGrid = (data: IAwardResponse[]): { awards: IAward[], years: string[] } => {
  let awards: IAward[] = []
  let years: string[] = []
  let grid: IAward[] = []
  for (let award of data) {
    const year = award?.date ? moment(award.date).format(YEAR_FORMAT) : null
    if (year && !years.includes(year)) {
      years.push(year)
      award = {...award, id: `awards-year--${year}`}
    }
    if (award.isCard) {
      grid.push(award)
    } else {
      if (!!grid.length) {
        awards.push({
          title: '',
          description: '',
          grid: grid
        })
        grid = []
      }
      awards.push(award)
    }
  }
  if (!!grid.length) {
    awards.push({
      title: '',
      description: '',
      grid: grid
    })
  }
  return {awards, years: years.sort((a, b) => Number(b) - Number(a))}
}

/** Преобразование данных для страницы Отзывы */
export const generateReviews = (reviews: IStrapiData<IStrapiAttributes<IReviewResponse>[]>): IReview[] => {
  return (reviews?.data?.map(r => r.attributes)?.map((r) => ({
    ...r,
    cardType: r?.cardType?.data?.attributes?.type as CardType || null,
    preview: r?.preview ? {
      video: r.preview?.video?.data?.attributes || null,
      image: r.preview?.image?.data?.attributes || null
    } : null
  })) || []) as IReview[]
}

/** Преобразование данных для блока Отзывы на страницах Услуг */
export const generateServiceFeedback = (feedbacks: IServiceFeedbackResponse[]): IServiceFeedback[] => {
  return (feedbacks?.map((f) => {
      const r = f.review.data.attributes
      return {
        review: {
          ...r,
          cardType: r?.cardType?.data?.attributes?.type as CardType || null,
          preview: r?.preview ? {
            video: r?.preview?.video?.data?.attributes || null,
            image: r?.preview?.image?.data?.attributes || null
          } : null
        },
        cardType: f?.cardType?.data?.attributes?.type as CardType || null,
        color: f?.color || null
      }
    }
  ) || []) as IServiceFeedback[]
}

/** Преобразование данных для страниц Проектов */
export const generateProject = (projects: IStrapiData<IStrapiAttributes<IProjectResponse>[]>): IProject[] => {
  if (!projects) {
    return []
  }
  return projects?.data.map(p => {
    const {attributes} = p
    const cardType = attributes?.cardType?.data?.attributes?.type

    return {
      ...attributes,
      cardType: cardType ? cardType as CardType : null,
      image: !attributes?.image?.data?.attributes?.url ? null : {
        ...attributes?.image?.data?.attributes,
      },
      imageMobile: !attributes?.imageMobile?.data?.attributes?.url ? null : {
        ...attributes?.imageMobile?.data?.attributes,
      },
      icon: !attributes?.icon?.data?.attributes?.url ? null : {
        ...attributes?.icon?.data?.attributes,
      },
      tags: attributes?.tags?.data?.map((s: { attributes: ITag }) => ({
        ...s.attributes
      })) ?? []
    }
  }) as IProject[]
}

/** Преобразование данных для стараницы Новости */
export const generateNews = (news: IStrapiData<IStrapiAttributes<INewsResponse>[]>): INews[] => {
  return news?.data?.map(n => n.attributes).map(n => ({
    ...n,
    cardType: n?.cardType?.data?.attributes?.type as CardType || null,
    image: n?.image?.data?.attributes || null,
    imageMobile: n.imageMobile?.data?.attributes || null,
    case: n?.case?.data?.attributes?.slug || null
  })) || []
}

/** Преобразование данных для стараницы Блог */
export const generateBlog = (blogs: IStrapiAttributes<IBlogResponse>[]): IBlog[] => {
  return blogs?.map(b => b.attributes).map(b => ({
    ...b,
    detail: b?.detail ? b?.detail as IEntityDetail : null,
    cardType: b.cardType.data?.attributes?.type as CardType || null,
    image: b.image?.data?.attributes || null,
    tag: b.tag.data?.attributes || null,
    steps: []
  })) || []
}

/** Преобразование данных для стараницы просмотра Блога */
export const generateBlogDetail = (blog: IBlogResponse): IBlog => {
  const detail = blog.detail ? getEntityDetail(blog.detail) : null
  const steps = getSteps(blog.steps)
  return {
    ...blog,
    image: blog.image.data?.attributes || null,
    cardType: blog.cardType.data?.attributes.type as CardType || null,
    tag: blog.tag.data?.attributes || null,
    detail,
    steps
  }
}