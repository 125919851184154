import {IReviewPageResponse, IStrapiAttributes, IStrapiData,} from '@redux/types';
import {call, put, takeEvery} from 'redux-saga/effects';
import api from '@api/index';
import {EReviewActionType, IReviewPageGetAction} from '@redux/reviews/types';
import {generateReviews} from '@common/utils/generateData';
import {getStrapiQuery} from '@common/functions';
import * as actions from './actions';

function* getReviewsPage({payload}: IReviewPageGetAction) {
  try {
    const {locale} = payload
    const query = getStrapiQuery({
      populate: 'deep,4',
      locale
    })
    const response: IStrapiData<IStrapiAttributes<IReviewPageResponse>> = yield call(() => api.get('/reviews-page', {query}))
    const data: IReviewPageResponse = response.data.attributes
    const {title, meta} = data
    const reviews = generateReviews(data.reviews)
    yield put(actions.setReviews({
      page: {title, meta},
      reviews
    }))
  } catch (e) {
    console.log('Fetch failed, reason: ', e)
  }
}


export default [
  takeEvery(EReviewActionType.GET_REVIEWS_PAGE, getReviewsPage),
]
