import {applyMiddleware, createStore} from 'redux';
import {createWrapper} from 'next-redux-wrapper';
import {reducer} from '@redux/reducers';
import rootSaga from '@redux/sagas';
import createSagaMiddleware from '@redux-saga/core';
import {SagaStore} from '@redux/types';

export const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const store: SagaStore = createStore(reducer, applyMiddleware(sagaMiddleware))
  store.sagaTask = sagaMiddleware.run(rootSaga)
  return store
}

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore['getState']>

export const wrapper = createWrapper<AppState>(makeStore, {debug: false})
