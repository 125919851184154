import {
  CardType,
  ICard,
  IHomePage,
  IHomePageResponse, IPartner,
  IPartnerResponse,
  ITag,
  THomeStepKey,
  THomeStepValue
} from '@redux/types';
import {TLang} from '@common/types';
import {IProject} from '@redux/projects/types';
import {generateAwardsGrid, generateNews} from '@common/utils/generateData';


/** Преобразование и группировка данных для блока Партнеры */
const generatePartnerGroup = (partners: IPartnerResponse[], locale: TLang): IPartner[] => {
  const emptyGroupName = locale === 'ru' ? 'Разное' : 'Miscellaneous'
  const grouped = partners?.reduce((r: { [key: string]: IPartnerResponse[] }, i: IPartnerResponse) => {
    r[i.tag.data?.attributes?.name ?? emptyGroupName] = r[i.tag.data?.attributes?.name ?? emptyGroupName] || []
    r[i.tag.data?.attributes?.name ?? emptyGroupName].push(i)
    return r
  }, {}) ?? []

  let groupedPartners: IPartner[] = []
  for (let key in grouped) {
    groupedPartners.push({
      name: key,
      group: grouped[key].map((g: IPartnerResponse) => ({
        ...g,
        logo: g.logo?.data?.attributes ?? null
      }))
    })
  }
  return groupedPartners
}

/** Преобразование и сортировка данных для Главной страницы */
export const generateHomePage = (home: IHomePageResponse, locale: TLang): IHomePage => {
  const homeFormatted = {
    ...home,
    banner: home?.banner ? {
      ...home.banner,
      imageMobile: home.banner.imageMobile.data?.attributes || null,
      image: home.banner.image.data.attributes,
    } : null,
    steps: {
      services: home?.services ? {
        ...home.services,
        services: home.services.services?.data?.map(s => s.attributes)?.map(s => {
          const cardType = s?.cardType?.data?.attributes?.type
          return {
            ...s,
            cardType: cardType ? cardType as CardType : null
          }
        }) ?? []
      } : null,
      news: {
        ...home.news,
        news: home?.news?.news ? generateNews(home.news.news) : []
      },
      partners: home?.partners ? {
        ...home.partners,
        partners: !!home.partners.partners?.data.length ?
          generatePartnerGroup(home.partners?.partners?.data?.map(r => r.attributes), locale) : []
      } : null,
      cases: home?.cases ? {
        ...home.cases,
        projects: home?.cases.projects?.data?.map(p => p.attributes)?.map(p => ({
          ...p,
          cardType: p.cardType?.data?.attributes?.type as CardType ?? null,
          icon: p?.icon?.data?.attributes ?? null,
          image: p?.image?.data?.attributes ?? null,
          imageMobile: p?.imageMobile?.data?.attributes ?? null,
          tags: p?.tags?.data?.map((s: { attributes: ITag }) => ({
            ...s.attributes
          })) || []
        })) as IProject[] ?? []
      } : null,
      products: home?.products ? {
        ...home.products,
        products: home.products?.products?.map(p => ({
          ...p,
          image: p?.image?.data?.attributes ?? null,
          type: p?.type?.data?.attributes?.type as CardType ?? null
        } as unknown as ICard)) ?? []
      } : null,
      awards: home?.awards ? {
        ...home.awards,
        awards: home?.awards?.awards ? generateAwardsGrid(home?.awards?.awards?.data?.map(a => a.attributes)).awards : []
      } : null,
      reviews: !!home?.reviews?.reviews?.filter(r => r.review.data)?.length ? {
        ...home.reviews,
        reviews: home?.reviews?.reviews ? home.reviews.reviews.map(r => ({
          ...r.review.data.attributes,
          cardType: r.cardType?.data?.attributes?.type ?? r.review.data?.attributes?.cardType?.data?.attributes.type ?? null,
          color: r.color ?? r.review.data.attributes.color,
          preview: r?.review.data.attributes.preview ? {
            video: r?.review.data.attributes.preview?.video?.data?.attributes || null,
            image: r?.review.data.attributes.preview?.image?.data?.attributes || null
          } : null
        })) : []
      } : null,
      slider: home.slider ? {
        ...home.slider,
        solutions: home.slider.solutions.data.map(s => s.attributes)
      } : null,
    }
  }

  return {
    ...homeFormatted,
    steps: Object.entries(homeFormatted?.steps)
      .map(o => ({key: o[0] as THomeStepKey, values: o[1] as THomeStepValue}))
      .filter(f => f?.values?.step)
      .sort((a, b) => a.values.index - b.values.index)
  } as IHomePage
}
