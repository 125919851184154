import update from 'immutability-helper';
import { EPartnersActionType } from './types';

const initialState = {
  partnersPage: null,
}

export const PartnersReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    // case EPartnersActionType.GET_PARTNERS_PAGE:
    //   return update(state, {$merge: action.payload})
    case EPartnersActionType.SET_PARTNERS_PAGE:
      return update(state, {$merge: action.payload})
    default:
      return state
  }
}
