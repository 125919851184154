import {IEntityPage, ILocale, INews} from '@redux/types';

export enum ENewsActionType {
  SET_NEWS = 'SET_NEWS',
  GET_NEWS_PAGE = 'GET_NEWS_PAGE',
}

export interface INewsState {
  page?: IEntityPage | null
  news?: INews[]
}

export interface INewsSetAction {
  type: ENewsActionType.SET_NEWS,
  payload: INewsState
}

export interface INewsPageGetAction {
  type: ENewsActionType.GET_NEWS_PAGE,
  payload: ILocale
}

export type INewsActions = INewsSetAction | INewsPageGetAction
