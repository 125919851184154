import {
  CardType,
  IServiceDetail,
  IServiceDetailResponse,
  IServicePage,
  IServicePageResponse,
  IStep,
  IStrapiAttributes,
  IStrapiData
} from '@redux/types';
import {call, put, takeEvery} from 'redux-saga/effects';
import api from '@api/index';
import * as actions from './actions';
import {EServiceActionType, IServiceDetailGetAction, IServicePageGetAction} from '@redux/services/types';
import {generateServiceFeedback} from '@common/utils/generateData';
import {getEntityDetail, getStrapiQuery} from '@common/functions';
import {getSteps} from '@common/utils/getSteps';


function* getServicePage({payload}: IServicePageGetAction) {
  try {
    const response: IStrapiData<IStrapiAttributes<IServicePageResponse>> = yield call(() => api.get('/service-page', {
      query: {
        populate: 'deep,3',
        locale: payload.locale
      }
    }))
    const data = response.data.attributes
    const steps: IStep[] = getSteps(data.steps)
    const servicePage: IServicePage = {
      ...data,
      services: data.services.data.map(s => {
        const {attributes} = s
        const cardType = attributes?.cardType?.data?.attributes?.type
        return {
          ...attributes,
          cardType: cardType ? cardType as CardType : null
        }
      }),
      steps
    }
    yield put(actions.setServices({
      page: {
        meta: servicePage.meta
      },
      servicePage
    }))
  } catch (e) {
    console.log('Fetch failed, reason: ', e)
  }
}

function* getService({payload}: IServiceDetailGetAction) {
  const fields = ['title', 'slug']
  const query = getStrapiQuery({
    fields,
    populate: 'deep,5',
    locale: payload?.locale || 'ru'
  })
  try {
    const response: IStrapiData<IStrapiAttributes<IServiceDetailResponse>> = yield call(() => api.get(`/services/${payload.slug}`, {
      query
    }))
    const data = response.data.attributes

    const detail = getEntityDetail(data.detail)
    const steps: IStep[] = getSteps(data.steps)
    const feedbacks = generateServiceFeedback(data.feedbacks)
    let service: IServiceDetail = {
      ...data,
      detail,
      feedbacks,
      steps
    }
    yield put(actions.setServices({
      service
    }))
  } catch (e) {
    yield put(actions.setServices({
      service: null
    }))
    // console.log('Fetch failed, reason: ', e)
  }
}

export default [
  takeEvery(EServiceActionType.GET_SERVICES_PAGE, getServicePage),
  takeEvery(EServiceActionType.GET_SERVICE, getService),
]
