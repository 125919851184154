import {
  EPartnersActionType,
  IPartnersPageGetAction,
  IPartnersPageSetAction,

} from '@redux/partners/types';
import {ILocale} from '@redux/types';


export const getPartnersPage = (payload: ILocale): IPartnersPageGetAction => ({
  type: EPartnersActionType.GET_PARTNERS_PAGE,
  payload
})


export const setPartnersPage = (payload: any): IPartnersPageSetAction => ({
  type: EPartnersActionType.SET_PARTNERS_PAGE,
  payload
})

