import {
  CardType,
  EBlockType,
  EComponent, ICardResponse, ICaseListResponse, IGridComponent, IGridComponentResponse, IListComponent,
  IListResponse,
  IMediaComponent,
  IMediaResponse, IQuoteComponent, IStackComponent, IStackComponentResponse,
  IStep,
  IStepComponent, ITag,
  TComponent
} from '@redux/types';
import {IProject} from '@redux/projects/types';

export const getSteps = (data: TComponent[]): IStep[] => {
  let i = 0
  let steps: IStep[] = []
  let currentStep: IStep | undefined = undefined
  for (let s of data) {
    if (s.__component === EComponent.STEP) {
      if (currentStep) {
        steps.push(currentStep)
      }
      const step = s as IStepComponent
      currentStep = {
        count: step?.displayStepNumber ? ++i : i,
        step: {
          ...step,
          type: step?.type || EBlockType.grid
        },
        children: []
      }
    } else {
      if (currentStep) {
        let st = null
        if (s.__component === EComponent.MEDIA) {
          st = s as unknown as IMediaResponse
          st = {
            ...s,
            placement: {position: st?.position, type: st?.type},
            image: st?.file && 'data' in st.file ? st?.file?.data?.attributes || null : null,
            imageMobile: st?.imageMobile && 'data' in st.imageMobile ? st?.imageMobile?.data?.attributes || null : null
          } as IMediaComponent
        }
        if (s.__component === EComponent.LIST) {
          st = s as unknown as IListResponse
          st = {
            ...st,
            lists: st?.lists?.map(item => ({
              ...item,
              tags: item?.tags?.split(',') || []
            }))
          } as IListComponent
        }
        if (s.__component === EComponent.CASE_LIST) {
          st = s as unknown as ICaseListResponse

          st = {
            ...s,
            cases: st?.cases?.data?.map(p => p.attributes)?.map(p => ({
              ...p,
              cardType: p.cardType?.data?.attributes?.type as CardType ?? null,
              icon: p?.icon?.data?.attributes ?? null,
              image: p?.image?.data?.attributes ?? null,
              imageMobile: p?.imageMobile?.data?.attributes ?? null,
              tags: p?.tags?.data?.map((s: { attributes: ITag }) => ({
                ...s.attributes
              })) || []
            })) as IProject[] ?? []
          }
        }
        if (s.__component === EComponent.QUOTE) {
          st = s as unknown as IQuoteComponent
          st = {
            ...s,
            person: {
              ...st?.person,
              fullName: st?.person?.fullName || st.employee?.data?.attributes?.fullName || '',
              position: st?.person?.position || st.employee?.data?.attributes?.position || '',
              image: {
                data: st?.person?.image?.data || st.employee?.data?.attributes?.image?.data || null
              },
              imageMobile: {
                data: st?.person?.imageMobile?.data || st.employee?.data?.attributes?.imageMobile?.data || null
              },
            }
          } as unknown as IQuoteComponent
        }
        if (s.__component === EComponent.GRID) {
          st = s as IGridComponentResponse
          const cards = st?.cards.map((c: ICardResponse) => ({
            ...c,
            image: c.image?.data?.attributes ?? null,
            type: c.type?.data?.attributes?.type ?? null
          })) || []
          st = {
            ...s,
            cards
          } as IGridComponent
        }
        if (s.__component === EComponent.STACK_LIST) {
          st = s as IStackComponentResponse
          const stacks = st?.stacks.map((c: ICardResponse) => ({
            ...c,
            image: c.image?.data?.attributes ?? null,
          })) || []
          st = {
            ...s,
            stacks
          } as IStackComponent
        }
        currentStep.children.push(st ?? s)
      }
    }
  }
  if (currentStep) {
    steps.push(currentStep)
  }
  return !!steps.length ? steps : []
}