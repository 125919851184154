import {IStrapiAttributes, IStrapiData,} from '@redux/types';
import {call, put, takeEvery} from 'redux-saga/effects';
import api from '@api/index';
import * as actions from './actions';
import {ETagActionType} from '@redux/tags/types';

function* getTags() {
  try {
    const response: IStrapiData<IStrapiAttributes<any>> = yield call(() => api.get('/tags?populate=*'))
    const data = response.data.attributes
    yield put(actions.setTags({
      tags: data || []
    }))
  } catch (e) {
    //console.log('Fetch failed, reason: ', e)
  }
}

export default [
  takeEvery(ETagActionType.GET_TAGS, getTags),
]
