import {
  EAppActionType,
  IAppPersonalInformationGetAction,
  IAppSendRequestFormPostAction,
  IAppSetAction,
  IAppSettingsGetAction,
  IAppState
} from '@redux/app/types';
import {ILocale, IRequestFormPayload } from '@redux/types';


export const setApp = (payload: IAppState): IAppSetAction => ({
  type: EAppActionType.SET_APP,
  payload
})

export const getAppSettings = (payload: ILocale): IAppSettingsGetAction => ({
  type: EAppActionType.GET_APP_SETTINGS,
  payload
})

export const getPersonalInformation = (): IAppPersonalInformationGetAction => ({
  type: EAppActionType.GET_PERSONAL_INFORMATION
})

export const sendRequestForm = (payload: IRequestFormPayload): IAppSendRequestFormPostAction => ({
  type: EAppActionType.SEND_REQUEST_FORM,
  payload
})
