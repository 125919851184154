import {wrapper} from '@redux/index';
import type {AppContext, AppProps} from 'next/app';
import {Provider} from 'react-redux';
import '@common/sass/style.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import {settings} from '@common/settings';
import {YMInitializer} from 'react-yandex-metrika';
import {GoogleAnalytics, GoogleTagManager} from '@next/third-parties/google';
import App from 'next/app';
import Head from 'next/head';
import urlJoin from 'url-join';
import {AppOriginProps} from '@common/types';


const ProjectApp = ({Component, ...rest}: AppOriginProps) => {
  const {store, props} = wrapper.useWrappedStore(rest)

  return (
    <Provider store={store}>
      <Head>
        <link rel="canonical" href={rest.canonicalHref} />
      </Head>
      {settings.keys.gaId && (
        <GoogleAnalytics gaId={settings.keys.gaId}/>
      )}
      {settings.keys.gtmId && (
        <GoogleTagManager gtmId={settings.keys.gtmId}/>
      )}
      {settings.keys.ymId && (
        <YMInitializer
          accounts={[settings.keys.ymId]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true
          }}
        />
      )}
      <Component {...props.pageProps} />
    </Provider>
  )
}

ProjectApp.getInitialProps = async (context: AppContext) => {
  const ctx = await App.getInitialProps(context)
  const siteUrl = settings.project.siteUrl
  const canonicalHref = urlJoin(
    siteUrl,
    context.ctx.locale !== 'ru' ? context.ctx.locale ?? '' : '',
    context?.ctx?.asPath !== '/' ? context?.ctx?.asPath ?? '' : ''
  )
  return {...ctx, canonicalHref}
}

export default ProjectApp
