import {Store} from 'redux';
import {Task} from 'redux-saga';
import {EPage, ICaseListItem, TLang, TRequestFormType} from '@common/types';
import {IProject} from '@redux/projects/types';

export enum EComponent {
  STEP = 'common.step',
  GRID = 'common.grid',
  ACCORDION = 'common.spoiler-list',
  BLOCK = 'common.block',
  LIST = 'common.list-list',
  STACK_LIST = 'common.stack-list',
  TAG_LIST = 'common.tag-list',
  CASE_LIST = 'common.case-list',
  QUOTE = 'common.quote',
  MEDIA = 'common.media',
  EMBED = 'common.embed-media',
  PARAGRAPH = 'common.paragraph',
  VACANCY_LIST = 'vacancy.vacancy-list',
  FORM_BLOCK = 'params.form-block',
}

export enum ESize {
  HALF = 'half',
  QUARTER = 'quarter'
}

export interface IMetadata {
  title?: string
  description?: string
  onlyPreview?: boolean
  pipelineId?: string | undefined
}

export type CardType = 'square' | 'circle' | 'rounded' | 'rounded-right'

export interface SagaStore extends Store {
  sagaTask?: Task
}

export interface ILocale {
  locale: TLang
}

export interface IDetail {
  slug: string
  locale?: TLang
}

export interface ICardType {
  type: string
  name: string
}

export interface ISection {
  type: EPage
  slug: string
}

export interface IStrapiPagination {
  page: number
  pageSize: number
  pageCount: number
  total: number
}

export interface IStrapiMeta {
  pagination: IStrapiPagination
}

export interface IStrapiData<T> {
  data: T,
  meta?: IStrapiMeta
}

export interface IStrapiAttributes<T> {
  id: number
  attributes: T
}

export interface IStrapiImageParams {
  name: string
  width: number
  height: number
  url: string
  mime: string
  size: number
  ext: string
  caption?: string
}

export interface IStrapiVideo extends IStrapiImageParams {
}

export interface IStrapiImage extends IStrapiImageParams {
  alternativeText: string
  formats: {
    thumbnail: IStrapiImageParams
    xlarge: IStrapiImageParams
    large: IStrapiImageParams
    medium: IStrapiImageParams
    small: IStrapiImageParams

    xlarge_x2: IStrapiImageParams
    large_x2: IStrapiImageParams
    medium_x2: IStrapiImageParams
    small_x2: IStrapiImageParams
  }
}

export interface IStrapiRichText {
  type: TStrapiRichTextType
  level?: number
  text?: string
  url?: string
  children?: IStrapiRichText[]
}

export interface IEntityDetail {
  title?: string | null
  description?: string | null
  text?: any[]
  media?: IMediaComponent | null
  embed?: IEmbedVideo | null
}

export interface IEntityDetailResponse extends Omit<IEntityDetail, 'media'> {
  media?: IMediaResponse | null
}

export type TStrapiRichTextType = 'heading' | 'paragraph' | 'link' | 'text'

export type TComponent =
  IStepComponent
  | IListComponent
  | IGridComponent
  | IMediaComponent
  | IParagraphComponent
  | IVacancyListComponent
  | IQuoteComponent
  | ITableComponent
  | IBannerComponent
  | IEmbedComponent
  | IAccordionComponent
  | ITagListComponent
  | IFormBlockComponent
  | IStackComponent
  | ICaseListComponent


export enum ESocialKeys {
  vc = 'vc',
  vk = 'vk',
  youtube = 'youtube',
  telegram = 'telegram',
  medium = 'medium',
  behance = 'behance',
}

export enum EEmbedMediaKeys {
  youtube = 'video/youtube',
  vimeo = 'video/vimeo'
}

// Models

export interface ISocial {
  [ESocialKeys.vc]: string
  [ESocialKeys.vk]: string
  [ESocialKeys.youtube]: string
  [ESocialKeys.telegram]: string
  [ESocialKeys.medium]: string
  [ESocialKeys.behance]: string
}

export interface IOffice {
  cityName: string
  phone: string
  address: string
  location: {
    coordinates: {
      lat: number
      lng: number
    }
  }
}

export interface IContacts {
  title: any
  email: string
  phone: string
  telegramChatUrl: string
  socialUrls: ISocial
  offices: IOffice[]
  meta: IMetadata
  file: IStrapiData<IStrapiAttributes<IStrapiImage> | null>
}

export interface IPersonalInformation {
  title: string
  agreement: {
    title: string
    description: string
  }
  conditions: string[]
}

export interface IPersonalInformationResponse extends Omit<IPersonalInformation, 'agreement' | 'conditions'> {
  agreement: {
    title: IStrapiRichText[]
    description: IStrapiRichText[]
  }
  conditions: IStrapiRichText[]
}

export interface IPreview {
  video: IStrapiVideo
  image: IStrapiImage
}

export interface IPreviewResponse {
  video: IStrapiData<IStrapiAttributes<IStrapiVideo>>
  image: IStrapiData<IStrapiAttributes<IStrapiImage>>
}

export interface IReview {
  description: string
  companyName: string | null
  customer: string | null
  cardType: CardType | null
  color: string | null
  preview?: IPreview | null
}

export interface IReviewResponse extends Omit<IReview, 'cardType' | 'preview'> {
  cardType: IStrapiData<IStrapiAttributes<ICardType> | null>
  preview: IPreviewResponse | null
}

export interface IServiceFeedbackResponse {
  review: IStrapiData<IStrapiAttributes<IReviewResponse>>
  cardType: IStrapiData<IStrapiAttributes<ICardType> | null>
  color: string | null
}

export interface IReviewPage extends IEntityPage {
  reviews: IReview[]
}

export interface IReviewPageResponse extends Omit<IReviewPage, 'reviews'> {
  reviews: IStrapiData<IStrapiAttributes<IReviewResponse>[]>
}

export interface IService {
  id: string
  slug: string
  title: string
  description: string
  color: string
  cardType: CardType | null
  meta: IMetadata | null
  localizations: IStrapiData<[]>
}

export interface IServiceResponse extends Omit<IService, 'cardType'> {
  cardType: IStrapiData<IStrapiAttributes<{ type: string, name: string }>>
}

export interface IProjectResponse extends Omit<IProject, 'cardType' | 'image' | 'imageMobile' | 'tags' | 'icon' | 'extendTags'> {
  date: string
  cardType: IStrapiData<IStrapiAttributes<{ type: string, name: string }>>
  image: IStrapiData<IStrapiAttributes<IStrapiImage>>
  imageMobile: IStrapiData<IStrapiAttributes<IStrapiImage>>
  icon: IStrapiData<IStrapiAttributes<IStrapiImage>>
  tags: IStrapiData<IStrapiAttributes<ITag>[]>
  extendTags?: IStrapiData<IStrapiAttributes<ITag>[]>
}

export interface IServiceFeedback {
  review: IReview
  cardType: CardType | null
  color: string | null
}

export interface IServiceDetail extends Omit<IService, 'description' | 'color' | 'cardType'> {
  detail: IEntityDetail
  steps: IStep[]
  feedbacks: IServiceFeedback[]
}

export interface IProjectDetail extends Omit<IProject, 'description' | 'color' | 'cardType'> {
  detail: IEntityDetail | null
  steps: IStep[]
  similarProjects: IProject[]
}

export interface IServiceDetailResponse extends Omit<IServiceDetail, 'feedbacks' | 'detail' | 'steps'> {
  steps: TComponent[]
  detail: IEntityDetailResponse
  feedbacks: IServiceFeedbackResponse[]
}

export interface IProjectDetailResponse extends Omit<IProjectDetail, 'similarProjects' | 'detail' | 'steps' | 'tags' | 'extendTags'> {
  steps: TComponent[]
  detail: IEntityDetailResponse
  similarProjects: IStrapiData<IStrapiAttributes<IProjectResponse>[]>
  tags: IStrapiData<IStrapiAttributes<ITag>[]>
  extendTags?: IStrapiData<IStrapiAttributes<ITag>[]>
}

export interface IStep {
  count: number
  step: IStepComponent
  children: TComponent[]
}

export interface IPage {
  title: string
  steps: IStep[]
  description?: string
  meta: IMetadata | null
}

export interface ICollectionItem extends IPage {
  id: string
}

export interface IServicePage extends IPage {
  services: IService[]
}

export interface IEntityPage {
  title?: string
  meta: IMetadata | null
}

export interface IServicePageResponse extends Omit<IServicePage, 'services' | 'steps'> {
  services: IStrapiData<IStrapiAttributes<IServiceResponse>[]>
  steps: TComponent[]
}

export interface ICard {
  id: number
  title: string
  description: string | null
  text?: any[]
  url: string | null
  urlTitle: string | null
  image?: IStrapiImage | null
  type?: CardType | null
}

export interface ICardResponse extends Omit<ICard, 'image' | 'type'> {
  image?: IStrapiData<IStrapiAttributes<IStrapiImage>>
  type?: IStrapiData<IStrapiAttributes<ICardType>>
}

export interface ISimpleTag {
  title: string
  id: string
}

export interface ITag {
  createdAt: string
  locale: string
  localizations: any
  name: string
  slug: string
  updatedAt: string
}

export interface IEmployees {
  fullName: string
  locale: string
  position: string
}

export interface IListItem {
  title: string,
  customTitle?: any[],
  description?: string | null
  text?: any[]
  tags?: string[]
}

export interface IAccordionItem {
  id: number
  opened: boolean
  text: any[]
  title: any[]
}

export interface ITableItem {
  title: string
  description?: string | null
  text?: any[]
}

export interface IVacancyListItem {
  slug: string
  title: string
  meta?: {
    onlyPreview?: boolean
  }
}

export interface IVacancyListItemProps {
  items: IVacancyListItem[]
}

export enum EBlockType {
  grid = 'inner-grid',
  simple = 'inner-simple-block',
  sticky = 'inner-sticky-block',
  table = 'inner-table',
  tools = 'inner-tools',
}

export enum EBlockPosition {
  insideStep = 'inside-step',
  outsideStep = 'outside-step',
  insideStepDescription = 'inside-step-description'
}

export type TStepChildPosition = EBlockPosition
export type TStepChildType = EBlockType

export interface IComponent {
  __component: EComponent
  id?: number
  placement?: { position: TStepChildPosition, type?: TStepChildType }
}

export interface IStepComponent extends IComponent {
  name: string
  title?: string | null
  anchor?: string
  description?: any
  note?: any
  type?: TStepChildType | 'inner-table' | 'inner-tools'
  childPosition?: TStepChildPosition
  displayStepNumber?: boolean
  hidden?: boolean
}

export interface IListComponent extends IComponent {
  // size: ESize | null
  lists: IListItem[]
  height?: string | null
}

export interface ICaseListComponent extends IComponent {
  // size: ESize | null
  // cases: ICaseListItem[]
  // height?: string | null
  cases: IProject[]
}


export interface ITagListComponent extends IComponent {
  // size: ESize | null
  tags: ISimpleTag[]
}

export interface IFormBlockComponent extends IComponent {
  color?: string
  title?: string
}

export interface IAccordionComponent extends IComponent {
  size: ESize | null
  spoilers: IAccordionItem[]
}

export interface ITableComponent extends IComponent {
  title?: string
  lists: ITableItem[]
}

export interface IMediaComponent extends IComponent {
  caption?: string
  file?: IStrapiData<IStrapiAttributes<IStrapiImage>>
  image: IStrapiImage | null
  url?: string
  embed?: string
  position?: EBlockPosition,
  type?: EBlockType,
  imageMobile?: IStrapiImage | null
}

export interface IEmbedComponent extends IComponent {
  embed: {
    video: string
  }
}

export interface IMedia extends Omit<IMediaComponent, '__component' | 'id' | 'placement' | 'imageMobile'> {

}

export interface IMediaResponse extends Omit<IMediaComponent, '__component' | 'id' | 'placement' | 'imageMobile' | 'position' | 'type'> {
  imageMobile?: IStrapiData<IStrapiAttributes<IStrapiImage>>
  position: string
  type?: string
}

export interface IListItemResponse extends Omit<IListItem, 'tags'> {
  tags?: string
}


export interface IListResponse extends Omit<IListComponent, '__component' | 'id' | 'placement' | 'lists'> {
  lists: IListItemResponse[]
}

export interface ICaseListItemResponse extends Omit<ICaseListItem, 'tags'> {
  project?: IStrapiData<IStrapiAttributes<IProjectResponse>>
  tags?: IStrapiData<IStrapiAttributes<ITag>[]>
}

export interface ICaseListResponse extends Omit<ICaseListComponent, '__component' | 'id' | 'placement' | 'cases'> {
  cases: IStrapiData<IStrapiAttributes<IProjectResponse>[]>
}


export interface IEmbedVideo {
  video: string,
  caption?: string
}

export interface IQuoteComponent extends IComponent {
  color: string
  employee: IStrapiData<IStrapiAttributes<{
    fullName: string
    image: IStrapiData<IStrapiAttributes<IStrapiImage>>
    imageMobile: IStrapiData<IStrapiAttributes<IStrapiImage>>
    position: string
  }>>
  id: number
  person: {
    fullName: string
    position: string,
    image?: IStrapiData<IStrapiAttributes<IStrapiImage> | null>
    video?: IStrapiData<IStrapiAttributes<IStrapiVideo> | null>
    imageMobile?: IStrapiData<IStrapiAttributes<IStrapiImage> | null>
  }
  height: string
  text?: any[]
  textColor: string
}

export interface ISimpleTextParams {
  classNames: string[]
}

export interface IBannerComponent extends IComponent {
  id: number
  margin: string
  type: string
  title: string
  description: string
  text?: any[]
  backgroundImage: IStrapiData<IStrapiAttributes<IStrapiImage>>
  imageMobile: IStrapiData<IStrapiAttributes<IStrapiImage>>
  backgroundColor?: string
}

export interface IGridComponent extends IComponent {
  cards: ICard[]
  height?: string | null
}

export interface IStackComponent extends IComponent {
  stacks: ICard[]
}

export interface IGridComponentResponse {
  cards: ICardResponse[]
}

export interface IStackComponentResponse {
  stacks: ICardResponse[]
}

export type VariantLink = 'accent' | 'animated';

export interface IParagraphComponent extends IComponent {
  text: any[]
  title?: string
  isBlock?: boolean
  variantLink?: VariantLink
}

export interface IVacancyListComponent extends IComponent {
  vacancies: IStrapiData<IStrapiAttributes<IVacancyListItem>[]>
}


export interface IAward {
  title: string
  description?: string
  date?: string
  url?: string
  isCard?: boolean
  grid?: IAward[]
  id?: string
}

export interface IAwardResponse extends Omit<IAward, 'grid'> {
}

export interface INews {
  title: string
  image: IStrapiImage | null
  imageMobile: IStrapiImage | null
  urlTitle?: string | null
  url?: string | null
  cardType?: CardType | null
  case?: string | null
}

export interface INewsResponse extends Omit<INews, 'image' | 'case' | 'cardType' | 'imageMobile'> {
  image: IStrapiData<IStrapiAttributes<IStrapiImage>>
  imageMobile: IStrapiData<IStrapiAttributes<IStrapiImage>>
  cardType: IStrapiData<IStrapiAttributes<ICardType> | null>
  case: IStrapiData<IStrapiAttributes<IProject> | null>
}

export interface INewsPageResponse {
  title: string
  meta: IMetadata
  news: IStrapiData<IStrapiAttributes<INewsResponse>[]>
}

export interface IBlog {
  title: string
  meta: IMetadata
  description: string
  slug: string
  url?: string
  detail: IEntityDetail | null
  image: IStrapiImage | null
  cardType: CardType | null
  tag: ITag | null
  steps: IStep[]
}

export interface IBlogResponse extends Omit<IBlog, 'image' | 'cardType' | 'tag' | 'steps' | 'detail'> {
  detail: IEntityDetailResponse | null
  image: IStrapiData<IStrapiAttributes<IStrapiImage> | null>
  cardType: IStrapiData<IStrapiAttributes<ICardType> | null>
  tag: IStrapiData<IStrapiAttributes<ITag> | null>
  steps: IStepComponent[]
}

export interface IBlogPage extends IEntityPage {
  tags: ITag[]
  blogs: IBlog[]
}

export interface IBlogPageResponse extends Omit<IBlogPage, 'tags' | 'blogs'> {
  tags: IStrapiData<IStrapiAttributes<ITag>[]>
  blogs: IStrapiData<IStrapiAttributes<IBlogResponse>[]>
}

export interface IPartnerGroup {
  name: string
  logo: IStrapiImage | null
}

export interface IPartner {
  name: string
  group: IPartnerGroup[]
}

export interface IPartnerResponse {
  name: string
  logo: IStrapiData<IStrapiAttributes<IStrapiImage> | null>
  tag: IStrapiData<IStrapiAttributes<ITag> | null>
}

export interface IHomeStep {
  step: IStepComponent
  index: number
}

// services

export interface IHomeService extends IHomeStep {
  services: IService[]
}

export interface IHomeServiceResponse extends Omit<IHomeService, 'services'> {
  services: IStrapiData<IStrapiAttributes<IServiceResponse>[]> | null
}

// news

export interface IHomeNews extends IHomeStep {
  news: INews[]
}

export interface IHomeNewsResponse extends Omit<IHomeNews, 'news'> {
  news: IStrapiData<IStrapiAttributes<INewsResponse>[]> | null
}

// partner

export interface IHomePartner extends IHomeStep {
  partners: IPartner[]
}

export interface IHomePartnerResponse extends Omit<IHomePartner, 'partners'> {
  partners: IStrapiData<IStrapiAttributes<IPartnerResponse>[]> | null
}

// cases

export interface IHomeCase extends IHomeStep {
  projects: IProject[]
}

export interface IHomeCaseResponse extends Omit<IHomeCase, 'projects'> {
  projects: IStrapiData<IStrapiAttributes<IProjectResponse>[]> | null
}

// products

export interface IHomeProduct extends IHomeStep {
  products: ICard[]
}

export interface IHomeProductResponse extends Omit<IHomeProduct, 'products'> {
  products: ICardResponse[] | null
}

// awards

export interface IHomeAward extends IHomeStep {
  awards: IAward[]
}

export interface IHomeAwardResponse extends Omit<IHomeAward, 'awards'> {
  awards: IStrapiData<IStrapiAttributes<IAwardResponse>[]> | null
}


// reviews

export interface IHomeReview extends IHomeStep {
  reviews: IReview[]
}

// slider

export interface ISolutionSlideTitle {
  text: string
  icon: IStrapiData<IStrapiAttributes<IStrapiImage> | null>
  iconPosition: 'left' | 'right' | null
}

export interface ISolutionSlide {
  text: IStrapiRichText
  title: ISolutionSlideTitle[]
}

export interface ISolution {
  slug: string
  slide: ISolutionSlide
}

export interface ISolutionDetail {
  detail: {
    cards: {
      title: string,
      description: string,
      color: string,
      cardType: string | null
    }[],
    title: string
  }
  meta: IMetadata | null
  steps: IStep[]
  slug: string
  title: string
  description: string
}

export interface ISolutionDetailResponse extends Omit<ISolutionDetail, 'detail' | 'steps'> {
  steps: TComponent[]
  detail: {
    cards: {
      title: string,
      description: string,
      color: string,
      cardType: IStrapiData<IStrapiAttributes<{ type: string, name: string }>>
    }[],
    title: string
  }
}

export interface IHomeSolution extends IHomeStep {
  title: string
  solutions: ISolution[]
}

export interface IHomeSliderResponse extends Omit<IHomeSolution, 'solutions'> {
  solutions: IStrapiData<IStrapiAttributes<ISolution>[]>
}

export interface IHomeReviewResponse extends Omit<IHomeReview, 'reviews'> {
  reviews: {
    color: string
    cardType: IStrapiData<IStrapiAttributes<ICardType> | null>
    review: IStrapiData<IStrapiAttributes<IReviewResponse>>
  }[]
}

export interface IHomeStatistic {

  projectTitle: string | null
  experienceTitle: string | null
  ratingTitle: string | null
}

export interface IHomeBanner {
  title: string
  image: IStrapiImage
  imageMobile: IStrapiImage | null
  button: {
    title: string
    url: string
  }
}

export interface IHomeBannerResponse extends Omit<IHomeBanner, 'image' | 'imageMobile'> {
  image: IStrapiData<IStrapiAttributes<IStrapiImage>>
  imageMobile: IStrapiData<IStrapiAttributes<IStrapiImage> | null>
}

export type THomeStepKey =
  'services'
  | 'news'
  | 'partners'
  | 'cases'
  | 'products'
  | 'awards'
  | 'reviews'
  | 'banner'
  | 'slider'

export type THomeStepValue =
  IHomeSolution
  | IHomeService
  | IHomeNews
  | IHomePartner
  | IHomeCase
  | IHomeProduct
  | IHomeAward
  | IHomeReview

export interface IHomePageStep {
  key: THomeStepKey
  values: THomeStepValue
}

export interface IHomePage {
  meta: IMetadata
  title: string
  statistics: IHomeStatistic
  banner: IHomeBanner | null
  steps: IHomePageStep[]
}

export interface IHomePageResponse extends Omit<IHomePage, 'services' | 'news' | 'partners' | 'cases' | 'products' |
  'awards' | 'reviews' | 'banner' | 'slider'> {
  services: IHomeServiceResponse
  news: IHomeNewsResponse
  partners: IHomePartnerResponse
  cases: IHomeCaseResponse
  products: IHomeProductResponse
  awards: IHomeAwardResponse
  reviews: IHomeReviewResponse
  banner: IHomeBannerResponse
  slider: IHomeSliderResponse
}

export interface IRequestFormData {
  nameContact?: string
  nameCompany?: string
  description?: string
  phone?: string
  email?: string
  responsible?: string
  file?: File
  tasks?: string
  source?: string
  pipeline_id?: string
}

export interface IRequestPartnerFormData {
  description?: string;
  emailContacts?: string;
  file?: string;
  nameCompanyContacts: string;
  nameContact: string;
  positionContacts: string;
  siteContacts: string;
  source: string
  surnameContacts: string;
  tasks: string;
  telegramContacts: string;
  pipeline_id?: string;
}

export interface IRequestFormPayload {
  data: IRequestFormData | IRequestPartnerFormData
  type: TRequestFormType
}

export interface IModalVideoProps {
  open?: boolean
  video?: IStrapiVideo | null
}

export interface IAppButton {
  title: string
  // url?: string
  // color?: 'violet'
  // form?: 'modal' | 'request'
  type?: 'radius-right' | 'radius-small'
}

export interface IAppFormField {
  placeholder: string
  key: string
  type: 'text' | 'email' | 'phone'
}

export interface IAppSettingsFormModal {
  button: IAppButton
  fields: IAppFormField[]
  submitButtonTitle?: string
}

export interface IAppSettings {
  formModal?: IAppSettingsFormModal
}
