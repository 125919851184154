import {ICollectionItem, IEntityDetail, IPage} from '@redux/types';

export enum EVacancyActionType {
  GET_VACANCIES_PAGE = 'GET_VACANCIES_PAGE',
  GET_VACANCY = 'GET_VACANCY',
  SET_VACANCIES = 'SET_VACANCIES',
}

export interface IVacancy extends ICollectionItem {
  slug: string
  detail?: IEntityDetail | null
}

export interface IVacancyPage extends IPage {
  detail?: IEntityDetail | null
}

export interface IVacanciesState {
  vacanciesPage?: IVacancyPage | null,
  vacancies?: IVacancy[],
  isFetchingLoading?: boolean,
  vacancy?: IVacancy | null
}

export interface IVacanciesPageGetAction {
  type: EVacancyActionType.GET_VACANCIES_PAGE,
}

export interface IVacancyGetAction {
  type: EVacancyActionType.GET_VACANCY,
  payload: { slug: string }
}

export interface IVacanciesSetAction {
  type: EVacancyActionType.SET_VACANCIES,
  payload: IVacanciesState
}

export type IVacanciesActions = IVacanciesSetAction | IVacanciesPageGetAction | IVacancyGetAction
