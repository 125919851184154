import {
  IStep,
  IStrapiAttributes,
  IStrapiData,
} from '@redux/types';
import {call, put, takeEvery} from 'redux-saga/effects';
import api from '@api/index';
import * as actions from './actions';
import {getSteps} from '@common/utils/getSteps';
import {getStrapiQuery} from '@common/functions';
import { EPartnersActionType, IPartnersPage, IPartnersPageGetAction } from './types';


function* getPartnersPage({payload}: IPartnersPageGetAction) {
  try {
    let query = getStrapiQuery({
      populate: 'deep,4'
    })
    const response: IStrapiData<IStrapiAttributes<any>> = yield call(() => api.get('/partners-page', {query}))

    const data = response.data.attributes

    const steps: IStep[] = getSteps(data.steps)

    yield put(actions.setPartnersPage({
      partnersPage: {
        ...data,
        steps,
      } as IPartnersPage,
    }))
  } catch (e) {
    console.log('Fetch failed, reason: ', e)
  }
}

export default [
  takeEvery(EPartnersActionType.GET_PARTNERS_PAGE, getPartnersPage),
]
