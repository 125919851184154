import {ITag} from '@redux/types';

export enum ETagActionType {
  GET_TAGS = 'GET_TAGS',
  SET_TAGS = 'SET_TAGS',
}


export interface ITagsState {
  tags?: ITag[],
  isFetchingLoading?: boolean,
  tag?: ITag | null
}

export interface ITagsGetAction {
  type: ETagActionType.GET_TAGS,
}

export interface ITagsSetAction {
  type: ETagActionType.SET_TAGS,
  payload: ITagsState
}

export type ITagsActions = ITagsGetAction | ITagsSetAction
